<template>
  <div
    v-if="moduleComponent"
    class="d-flex flex-grow-1"
  >
    <component
      :is="moduleComponent"
      class="flex-1"
      :module-name="module"
    />
  </div>
</template>

<script>
export default {
  name: "ModulePanel",
  props: {
    module: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      moduleComponent: undefined
    }
  },
  mounted() {
    let props = this.$router.resolve({name: this.module})
    this.moduleComponent = this.$router.getMatchedComponents(props.location).pop()
  }
}
</script>

<style scoped>

</style>
