<template>
  <v-dialog
    v-model="sessionDialog"
    max-width="500px"
    @click:outside="closeSessionDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          Start session
        </span>
      </v-card-title>
      <v-form
        ref="form"
        @submit.prevent
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-text-field
                  v-model="workflowSessionItem.name"
                  :rules="[rules.required, rules.maxLength]"
                  label="name"
                  counter="250"
                  maxlength="250"
                />
              </v-col>
              <v-col
                v-if="sbsRecords.length > 0"
                cols="12"
                sm="6"
                md="12"
              >
                <v-combobox
                  v-model="workflowSessionItem.sbs"
                  clearable
                  :items="sbsRecords"
                  item-text="code"
                  item-value="code"
                  label="sbs code"
                >
                  <template v-slot:item="{item}">
                    <div
                      v-if="item.label"
                      class="d-flex align-center"
                    >
                      {{ item.label }}
                      <v-divider
                        vertical
                        class="mx-2"
                      />
                      <span style="font-size: 11px; color: grey">
                        {{ item.code }}
                      </span>
                    </div>
                    <div
                      v-else
                    >
                      {{ item.code }}
                    </div>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="closeSessionDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="startSessionForProjectWorkflow"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "WorkflowSessionDialog",
  props: {
    sessionDialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    workflowSessionItem: {},
    rules: {
      required: (value) => !!value || "Required.",
      maxLength: (value) => (value && value.length < 250) || 'Max 250 characters'
    },
  }),
  computed: {
    ...mapGetters([
      "project",
      "sbsRecords",
    ]),
  },
  mounted() {
    if (this.project.id !== '') {
      this.$store.dispatch("loadSbsRecords", {projectId: this.project.id});
    }
  },
  methods: {
    startSessionForProjectWorkflow() {
      if (this.$refs.form.validate()) {
        // create body
        let body = {
          name: this.workflowSessionItem.name,
          workflow: this.$route.params.workflowId,
        };

        if (this.workflowSessionItem.sbs) {
          body.sbs_code = this.workflowSessionItem.sbs.code
        }

        // create session
        this.$store.dispatch("createWorkflowSession",
            {
              projectId: this.project.id,
              body
            })
            .then((session) => {
              this.closeSessionDialog();
              this.$router.push({
                name: 'project-workflow-session', params: {
                  workflowId: this.$route.params.workflowId,
                  sessionId: session.id
                }
              })
            });
      }
    },
    closeSessionDialog() {
      this.workflowSessionItem = Object.assign({}, {})
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>

</style>
