<template>
  <div v-if="configuration"
    :style="{overflow: ['form'].includes(configuration.type) ? 'scroll' : 'hidden'}"
  >
    <media-panel
      v-if="configuration.type === 'media'"
      :media="configuration.content"
    />

    <table-panel
      v-if="configuration.type === 'tables'"
      style="width: 100%"
      :tables="configuration.content.tables"
    />

    <markdown-panel
      v-if="configuration.type === 'markdown'"
      :markdown="configuration.content"
    />

    <form-panel
      v-if="configuration.type === 'form'"
      :form-config="configuration.content"
      :session="$route.params.sessionId"
      @finished-form="finishJobAssignment"
    />

    <module-panel
      v-if="configuration.type === 'modules'"
      :module="configuration.content.module"
      class="d-flex flex-grow-1"
    />

    <module-panel v-if="configuration.type === 'esri'" module="esri" />

    <module-panel v-if="configuration.type === 'forge'" module="forge" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TablePanel from '@/components/Project/Workflows/Panels/TablePanel';
import MarkdownPanel from '@/components/Project/Workflows/Panels/MarkdownPanel';
import FormPanel from '@/components/Project/Workflows/Panels/FormPanel';
import ModulePanel from '@/components/Project/Workflows/Panels/ModulePanel';

export default {
  name: 'WorkflowNodePanel',
  components: {
    ModulePanel,
    FormPanel,
    MarkdownPanel,
    TablePanel,
    MediaPanel: () =>
      import(
        /* webpackChunkName: "workflows" */ '@/components/Project/Workflows/Panels/MediaPanel'
      ),
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      configuration: undefined,
    };
  },
  computed: {
    ...mapGetters(['nodeConfig']),
  },
  mounted() {
    this.configuration =
      this.nodeConfig.panels[this.position.horizontal][this.position.vertical];
  },
  methods: {
    finishJobAssignment() {
      this.$emit('finish-job');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
