<template>
  <div v-if="selectedProjectWorkflow" class="workflow-sessions">
    <div
      class="ant-header radius-0 ant-border-bottom pos-rel d-flex align-center mb-0"
    >
      <div class="d-flex justify-start">
        <v-btn
          color="transparent"
          class="elevation-0"
          small
          :ripple="false"
          @click="navigateToProjectWorkflows"
        >
          <v-icon left> mdi-chevron-left </v-icon>
          workflows
        </v-btn>
      </div>
      <div class="flex-1 d-flex justify-center">
        <span>Workflow '{{ selectedProjectWorkflow.name }}' Sessions</span>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          :disabled="
            $can('start', 'project-workflow-session')
              ? false
              : !projectWorkflowPermissions['start-session']
          "
          x-small
          @click="sessionDialog = true"
        >
          Start Session
        </v-btn>
      </div>
    </div>
    <div class="workflow-sessions-list pa-5">
      <div
        v-for="(session, index) in sortedSessions"
        :key="session.id"
        class="ant-glass-background session"
        @click="selectSession(session.id)"
      >
        <span>{{ session.name }}</span>
        <v-divider v-if="session.sbs_code" vertical style="margin: 0 10px" />
        <span
          v-if="session.sbs_code"
          style="font-style: italic; font-size: 12px"
          >{{ session.sbs_code }}</span
        >

        <v-spacer />
        <div class="session-created-at">
          <v-icon dense> mdi-calendar </v-icon>
          <span>{{ session.created_at }}</span>
        </div>
        <v-progress-linear
          color="primary"
          height="20"
          style="width: 200px"
          :value="getSessionProgress(session)"
        >
          <template #default="{ value }">
            <strong>{{ getSessionProgress(session) }}%</strong>
          </template>
        </v-progress-linear>
        <v-btn
          icon
          small
          class="session-delete-btn"
          @click.stop="setupDelete(session)"
        >
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </div>
    </div>
    <delete-dialog
      :dialog="sessionDeleteDialog"
      :title="`Are you sure you want to delete session '${sessionItem.name}'?`"
      @closeDialog="closeSessionDialogs"
      @deleteAction="deleteSession"
    />
    <workflow-session-dialog
      :session-dialog="sessionDialog"
      @closeDialog="sessionDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WorkflowSessionDialog from '@/components/Dialogs/WorkflowSessionDialog';
import DeleteDialog from '@/components/DeleteDialog';
import moment from 'moment';

export default {
  name: 'WorkflowSessions',
  components: { DeleteDialog, WorkflowSessionDialog },
  data: () => {
    return {
      sessionDialog: false,
      sessionDeleteDialog: false,
      sessionItem: {},
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'sessions',
      'selectedProjectWorkflow',
      'projectWorkflowPermissions',
    ]),

    sortedSessions() {
      return [...this.sessions].sort((a, b) =>
        moment(b.created_at).diff(moment(a.created_at))
      );
    },
  },
  mounted() {
    if (this.selectedProjectWorkflow === undefined) {
      this.$store.dispatch('setProjectWorkflow', this.$route.params.workflowId);
    }
    this.$store.dispatch('fetchProjectWorkflowSessions', {
      projectId: this.project.id,
      projectWorkflowId: this.$route.params.workflowId,
    });
  },
  methods: {
    selectSession(sessionId) {
      this.$store.dispatch('setSession', sessionId);
      this.$router.push({
        name: 'project-workflow-session',
        params: {
          workflowId: this.$route.params.workflowId,
          sessionId: sessionId,
        },
      });
    },
    closeSessionDialogs() {
      this.sessionItem = Object.assign({}, {});
      this.sessionDialog = false;
      this.sessionDeleteDialog = false;
    },
    setupDelete(session) {
      this.sessionItem = session;
      this.sessionDeleteDialog = true;
    },
    navigateToProjectWorkflows() {
      this.$router.push({ name: 'project-workflows' });
    },
    deleteSession() {
      this.$store
        .dispatch('deleteWorkflowSession', {
          projectId: this.project.id,
          sessionId: this.sessionItem.id,
        })
        .then(() => {
          this.closeSessionDialogs();
        });
    },
    getSessionProgress(session) {
      if (session.jobs !== undefined) {
        let total = session.jobs.length;
        let completed = session.jobs.filter(
          (job) => job.status === 'completed'
        ).length;

        return Math.floor((completed / total) * 100);
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
.workflow-sessions {
  flex: 1;
  display: flex;
  flex-direction: column;

  .workflow-sessions-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;

    .session {
      padding: 10px 25px;
      margin: 5px 0;
      display: flex;
      align-items: center;
      transition: background-color 200ms;
      cursor: pointer;
      box-sizing: border-box;

      .session-created-at {
        margin: 0 40px;
        font-size: 12px;
        display: flex;
        align-items: center;

        .v-icon {
          margin-right: 10px;
        }
      }

      .session-delete-btn {
        opacity: 0;
        transition: 200ms;
        margin-left: 20px;
      }

      &:hover {
        border-left: solid 3px var(--v-primary-base);

        .session-delete-btn {
          opacity: 1;
        }
      }

      &.active {
        border-left: solid 3px var(--v-primary-base);
      }
    }
  }
}
</style>
