<template>
  <v-layout style="max-height: 100%">
    <v-flex style="overflow: auto">
      <dynamic-data-table
        v-if="selectedTable"
        :table-title="selectedTable.name"
        :table-headers="headers"
        :table-records="records"
        :table-columns="columns"
        :is-loading="isTableLoading"
        :project-id="project.id"
        :table-id="selectedTable.id"
        :can-delete="selectedTable.permissions.delete && !workflowJobCompleted"
        @deleteItem="setupDelete"
      >
        <template slot="table-actions">
          <v-switch
            v-model="sessionFilterToggle"
            hide-details
            label="Session Filter"
            color="primary"
            style="margin-right: 50px"
            @change="getRecords()"
          />
          <v-select
            v-model="selectedTableId"
            :items="panelTables"
            item-value="id"
            item-text="name"
            dense
            hide-details
            @change="setPanelTable()"
          />
        </template>
        <template
          v-for="header in headers.filter((tmp) => tmp.hasSlot)"
          v-slot:[`item.${header.value}`]="{ value, rowId, item }"
        >
          <div
            v-if="selectedTable.permissions.update && !workflowJobCompleted"
            :key="header.value"
            class="editable-field"
          >
            <v-edit-dialog
              :return-value.sync="item[header.value]"
              large
              @save="save(item.id, header.value, item[header.value])"
            >
              {{ item[header.value] }}
              <v-icon
                class="edit-icon"
                small
              >
                mdi-pencil
              </v-icon>
              <template v-slot:input>
                <v-text-field
                  v-model="item[header.value]"
                  label="Edit"
                  single-line
                />
              </template>
            </v-edit-dialog>
          </div>
          <div v-else>
            {{ item[header.value] }}
          </div>
        </template>
      </dynamic-data-table>
      <delete-dialog
        :dialog="recordDeleteDialog"
        title="Are you sure you want to remove this record?"
        @closeDialog="closeRecordDialogs"
        @deleteAction="deleteRecord()"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import {mapGetters} from "vuex";
import {getTables} from "@/services/api/table.api";
import {getColumns} from "@/services/api/column.api";
import {getFilteredRecords, getRecords, updateRecord} from "@/services/api/record.api";
import DynamicDataTable from "@/components/DynamicDataTable";
import moment from "moment";
import DeleteDialog from "@/components/DeleteDialog";

export default {
  name: "TablePanel",
  components: {DeleteDialog, DynamicDataTable},
  props: {
    tables: {
      type: Array,
      required: false
    }
  },
  data: () => {
    return {
      panelTables: undefined,
      selectedTable: undefined,
      selectedTableId: undefined,
      records: [],
      headers: [],
      columns: [],
      sessionFilterToggle: true,
      isTableLoading: false,
      recordItem: {},
      recordDeleteDialog: false,
    }
  },
  computed: {
    ...mapGetters(['project', 'selectedSession', 'selectedNode']),
    workflowJobCompleted() {
      return this.selectedNode.job.status === 'completed'
    }
  },
  watch: {
    selectedTable(value) {
      this.selectedTableId = value.id
      this.getRecords()
      this.getColumns()
    }
  },
  mounted() {
    if (this.tables) {
      getTables(this.project.id).then(tables => {
      this.panelTables = tables.filter(table => this.tables.find(x => x === table.name))
      if (this.panelTables.length > 0) {
        this.selectedTable = this.panelTables[0]
      } else {
        this.$store.commit("showNotification", {content: 'Tables not found within project', color: 'error'})
      }
    })
    }
  },
  methods: {
    closeRecordDialogs() {
      this.recordDeleteDialog = false
      this.recordItem = Object.assign({}, {})
    },
    setupDelete(record) {
      this.recordItem = Object.assign({}, record)
      this.recordDeleteDialog = true
    },
    deleteRecord() {
      let body = {
        project: {
          id: this.selectedTable.project
        },
        table: {
          id: this.selectedTable.id
        }
      }

      this.$store.dispatch("deleteRecord", {recordId: this.recordItem.id, body})
          .then(() => {
            this.records = [...this.records.filter(record => record.id !== this.recordItem.id)]
            this.closeRecordDialogs()
          })
    },
    setPanelTable() {
      this.selectedTable = this.panelTables.find(x => x.id === this.selectedTableId)
    },
    getRecords() {
      this.isTableLoading = true
      const {itemsPerPage, page} = {
        itemsPerPage: 0,
        page: 1,
      };

      let filter = {
        offset: itemsPerPage * (page - 1),
        limit: itemsPerPage,
      };

      if(this.sessionFilterToggle) {
        filter.session = this.$route.params.sessionId
      }

      getFilteredRecords(this.project.id, this.selectedTable.id, filter, this.workflowJobCompleted ? moment.utc(this.selectedNode.job.updated_at).unix() : null).then(data => {
        this.records = data
        this.isTableLoading = false
      })
    },
    getColumns() {
      getColumns(this.project.id, this.selectedTable.id).then(columns => {
        let keysArray = []
        columns.forEach(column => {
          let label = {text: column.name, value: column.name}
          if(column.relation === null && column.type !== 'document') {
            label.hasSlot = true
          }
          keysArray.push(label)
        })
        if(this.selectedTable.permissions.delete && !this.workflowJobCompleted) {
          keysArray.push({
            text: 'actions',
            value: 'actions',
            align: 'right',
            sortable: false,
            width: 50
          })
        }

        this.headers = keysArray

        this.columns = columns
      })
    },
    save(recordId, column, value) {
      let body = {
        project: {
          id: this.selectedTable.project,
        },
        table: {
          id: this.selectedTable.id
        },
        session: {
          id: this.$route.params.sessionId
        },
        record: {},
      };

      body.record[column] = value;

      updateRecord(recordId, body)
          .catch(() => {
            getRecords(this.project.id, this.selectedTable).then(records => {
              this.tableRows = records
            })
          })
    },
  }
}
</script>

<style scoped lang="scss">
.editable-field {
  display: flex;

  .edit-icon {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .edit-icon {
      opacity: 1;
    }
  }
}

</style>
