import { render, staticRenderFns } from "./WorkflowManagementRoles.vue?vue&type=template&id=7632285c&scoped=true"
import script from "./WorkflowManagementRoles.vue?vue&type=script&lang=js"
export * from "./WorkflowManagementRoles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7632285c",
  null
  
)

export default component.exports