<template>
  <dynamic-data-table
    table-title="Blocks"
    :table-records="filteredNodes"
    :table-headers="nodeHeaders"
    :is-loading="projectWorkflowNodesStatus === 'loading'"
  >
    <template v-slot:item.block="{value}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            {{ value.name }}
          </span>
        </template>
        <span>{{ value.description }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.roles="{ value, rowId, item}">
      <v-chip
        v-for="role in value"
        :key="role.id"
        class="ma-2"
        close
        :color="role.color"
        :text-color="getContrastColor(role.color)"
        @click:close="removeRoleFromNode(rowId, role)"
      >
        {{ role.name }}
      </v-chip>
    </template>
    <template v-slot:table-actions>
      <span v-if="getNonConfiguredNodes.length > 0"> {{ getNonConfiguredNodes.length }} nodes do not have a role assigned</span>
    </template>
    <template v-slot:table-row-actions="{item}">
      <v-tooltip
        v-if="selectedRole !== null && item.roles.findIndex(x => x.id === selectedRole.id) === -1"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            small
            :disabled="selectedRole === null"
            v-on="on"
            @click="addRoleToNode(item)"
          >
            mdi-account-key
          </v-icon>
        </template>
        <span>Add "{{ selectedRole.name }}" to team</span>
      </v-tooltip>
    </template>
  </dynamic-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import DynamicDataTable from "@/components/DynamicDataTable";
import ColorHelper from "@/services/task-helper";

export default {
  name: "WorkflowManagementNodes",
  components: {DynamicDataTable},
  data: () => ({
    nodeHeaders: [
      {
        text: "name",
        value: "block",
        hasSlot: true
      },
      {
        text: "roles",
        value: "roles",
        hasSlot: true
      },
      {text: "Actions", value: "actions", align: 'right', sortable: false},
    ],
  }),
  computed: {
    ...mapGetters(['project', 'projectWorkflowNodes', 'projectWorkflowNodesStatus', 'filteredRole', 'selectedRole']),

    filteredNodes() {
      if (this.filteredRole) {
        return this.projectWorkflowNodes.filter(node => node.roles.findIndex(role => role.id === this.filteredRole.id) !== -1)
      }
      return this.projectWorkflowNodes
    },
    getNonConfiguredNodes() {
      return this.projectWorkflowNodes.filter(item => item.roles.length === 0)
    }
  },
  mounted() {
    this.$store.dispatch("fetchProjectWorkflowNodes", {
      projectId: this.project.id,
      workflowId: this.$route.params.workflowId
    })
  },
  methods: {
    addRoleToNode(node) {
      this.$store.dispatch("addRoleToNode", {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: this.selectedRole,
        node: node
      })
    },
    removeRoleFromNode(nodeId, role) {
      this.$store.dispatch("removeRoleFromNode", {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: role,
        node: nodeId
      })
    },
    getContrastColor(colorHex) {
      return ColorHelper.getContrastColor(colorHex);
    }
  }
}
</script>

<style scoped>

</style>
