<template>
  <dynamic-data-table
    table-title="Teams"
    :table-records="filteredTeams"
    :table-headers="teamHeaders"
    :is-loading="projectWorkflowTeamsStatus === 'loading'"
  >
    <template v-slot:item.roles="{ value, rowId, item}">
      <v-chip
        v-for="role in value"
        :key="role.id"
        class="ma-2"
        close
        :color="role.color"
        :text-color="getContrastColor(role.color)"
        @click:close="removeRoleFromTeam(rowId, role)"
      >
        {{ role.name }}
      </v-chip>
    </template>
    <template v-slot:item.is_admin="{ value }">
      <v-checkbox
        disabled
        :input-value="value"
      />
    </template>
    <template v-slot:table-row-actions="{item}">
      <v-tooltip
        v-if="selectedRole !== null && item.roles.findIndex(x => x.id === selectedRole.id) === -1"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            small
            :disabled="selectedRole === null"
            v-on="on"
            @click="addRoleToTeam(item)"
          >
            mdi-account-key
          </v-icon>
        </template>
        <span>Add "{{ selectedRole.name }}" to team</span>
      </v-tooltip>
    </template>
  </dynamic-data-table>
</template>

<script>
import DynamicDataTable from "@/components/DynamicDataTable";
import {mapGetters} from "vuex";
import ColorHelper from "@/services/task-helper";

export default {
  name: "WorkflowManagementTeams",
  components: {DynamicDataTable},
  data: () => ({
    teamHeaders: [
      {
        text: "name",
        value: "name",
      },
      {
        text: "roles",
        value: "roles",
        hasSlot: true
      },
      {
        text: "admin",
        value: "is_admin",
        hasSlot: true
      },
      {text: "Actions", value: "actions", align: 'right', sortable: false},
    ],
  }),
  computed: {
    ...mapGetters(['project', 'projectWorkflowTeams', 'projectWorkflowTeamsStatus', 'selectedRole', 'filteredRole']),

    filteredTeams() {
      if (this.filteredRole) {
        return this.projectWorkflowTeams.filter(team => team.roles.findIndex(role => role.id === this.filteredRole.id) !== -1)
      }
      return this.projectWorkflowTeams
    }
  },
  mounted() {
    this.$store.dispatch("fetchProjectWorkflowTeams", {
      projectId: this.project.id,
      workflowId: this.$route.params.workflowId
    })
  },
  methods: {
    getContrastColor(colorHex) {
      return ColorHelper.getContrastColor(colorHex);
    },
    addRoleToTeam(team) {
      this.$store.dispatch("addRoleToTeam", {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: this.selectedRole,
        team
      })
    },
    removeRoleFromTeam(team, role) {
      this.$store.dispatch("removeRoleFromTeam", {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: role,
        teamId: team
      })
    },
  }
}
</script>

<style scoped>

</style>
